import { AccountId, OrgInvoice } from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { payIndividualOrgInvoice } from "../../utils/org-invoice-utils";

import { ChargeCardUnexpectedErrorStatusCodes, PayInvoiceUnexpectedErrorStatusCodes } from "../../utils/payment-helpers";

export async function orgInvoice__server__payIndividualOrgInvoiceAsOrgAdmin(p: {
  orgInvoice: OrgInvoice;
  todayPaymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
  };
  nonDefaultPaymentMethodIdToUse?: string;
  manualTriggerMS?: number;
  selfAccountId: AccountId;
  accountIdToCharge: AccountId;
  isAutoPayment: boolean;
  locale: string;
  isImmediateRetry?: boolean;
}): Promise<
  | { type: "success" }
  | {
      type: "error";
      prettyErrorReason: string;
      errorCode: PayInvoiceUnexpectedErrorStatusCodes | ChargeCardUnexpectedErrorStatusCodes;
    }
  | { type: "failed"; prettyFailureReason: string }
> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const org = await h.Org.getDoc(p.orgInvoice.orgId);
  if (!org || !org.accounts?.[p.selfAccountId]?.exists) {
    throw new Error("Can't process payments for this org");
  }

  return await payIndividualOrgInvoice({ ...p, accountId: p.accountIdToCharge });

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__payIndividualOrgInvoiceAsOrgAdmin.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
