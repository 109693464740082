import {
  CalendarEntry,
  CalendarEntryGameScrimmage,
  OrgInvoice,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgPayment,
  PlayerBundleId,
  SoccerStatModes
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import moment from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../../constants";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgInvoice__client__getOrgInvoicesForPlayerBundleIdsSubscription(p: {
  playerBundleIds: PlayerBundleId[];
  restrictToType?: OrgInvoiceTypes;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.OrgInvoice.multiQuerySubscription({
      queries: p.playerBundleIds.map(playerBundleId => {
        const obj: SimpleQuery<OrgInvoice> = {
          where: [{ playerBundleId: ["==", playerBundleId] }]
        };

        if (p.restrictToType) {
          obj.where!.push({ type: ["==", p.restrictToType] } as any);
        }

        return obj;
      }),
      mergeProcess: { orderBy: { sortKey: "createdAtMS", dir: "desc" } }
    })
  );
}

// i18n certified - complete
