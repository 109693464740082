import { getServerHelpers, getUniversalHelpers } from "../../helpers";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { isProduction } from "../../utils";
import { OrgInvoice__Manual, PlayerBundle__AccountType } from "@ollie-sports/models";
import { triggerForInvoiceNotifications } from "../notification/invoice-helpers";

export async function orgInvoice__server__sendManualOrgInvoiceReminders() {
  // SERVER_ONLY_TOGGLE
  console.log("--- sendManualOrgInvoiceReminders ---");

  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  olliePipe.emitEvent({
    type: "metric-send-invoice-reminders",
    payload: {}
  });

  const numDaysArray: { type: "reminder" | "pastDue"; numDays: number }[] = [
    {
      numDays: 7,
      type: "reminder"
    },
    {
      numDays: 3,
      type: "reminder"
    },
    {
      numDays: 1,
      type: "reminder"
    },
    ...([1, 3, 7, 14, 21, 28].map(num => {
      return { numDays: num, type: "pastDue" };
    }) as { type: "reminder" | "pastDue"; numDays: number }[])
  ];

  try {
    for (let i = 0; i < numDaysArray.length; i++) {
      const info = numDaysArray[i];
      const startDate =
        info.type === "reminder"
          ? // Add 13 hours so that this runs at 10 am local time
            moment().startOf("hour").add(info.numDays, "day").add(13, "hours").valueOf()
          : moment().startOf("hour").subtract(info.numDays, "day").add(13, "hours").valueOf();
      const endDate =
        info.type === "reminder"
          ? // Add 13 hours so that this runs at 10 am local time
            moment().endOf("hour").add(info.numDays, "day").add(13, "hours").valueOf()
          : moment().endOf("hour").subtract(info.numDays, "day").add(13, "hours").valueOf();

      const manualOrgInvoices = (
        await getAppPgPool().query(
          `select item as org_invoice
      from mirror_orginvoice
      where item ->> 'type' = 'manual'
        and item->> 'thisInvoicePaidInFullDateMS' = '0'
        and cast(item ->> 'dueDateMS' as numeric) > $1
        and cast(item ->> 'dueDateMS' as numeric) <= $2;`,
          [startDate, endDate]
        )
      ).rows.map(r => r["org_invoice"] as OrgInvoice__Manual);
      console.log([info.numDays, startDate, endDate, manualOrgInvoices.length]);
      olliePipe.emitEvent({
        type: "metric-send-invoice-reminders",
        payload: { type: "got-data", info, orgInvoiceIds: manualOrgInvoices.map(oi => oi.id) }
      });

      if (manualOrgInvoices.length) {
        olliePipe.emitEvent({
          type: "metric-send-manual-invoice-reminders",
          payload: { type: "success", orgInvoiceIds: manualOrgInvoices.map(a => a.id) }
        });
      }

      await triggerForInvoiceNotifications(
        info.type === "reminder"
          ? {
              type: "soonToBeDueReminder",
              orgInvoices: manualOrgInvoices,
              numDays: info.numDays
            }
          : {
              type: "pastDue",
              orgInvoices: manualOrgInvoices
            }
      );
    }
  } catch (e) {
    olliePipe.emitEvent({
      type: "metric-send-invoice-reminders",
      payload: { type: "error", error: e }
    });
  }
  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__sendManualOrgInvoiceReminders.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
