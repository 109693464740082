import { OrgId, OrgInvoiceChild, OrgInvoiceId, OrgInvoiceParent, OrgInvoiceTypes, OrgPaymentInvoice } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { isParentOrgInvoice } from "../../utils";

export async function orgInvoice__client__getParentOrgInvoiceAndAllRelatedInvoicesAndPayments(p: { id: OrgInvoiceId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let parentOrgInvoice = await h.OrgInvoice.getDoc(p.id);
  if (!parentOrgInvoice) {
    throw new Error("Could not load the invoice");
  }

  if (!isParentOrgInvoice(parentOrgInvoice)) {
    parentOrgInvoice = await h.OrgInvoice.getDoc(parentOrgInvoice.parentOrgInvoiceId);
    if (!parentOrgInvoice) {
      throw new Error("Could not load the invoice");
    }
  }

  const childrenOrgInvoicesData = await h.OrgInvoice.query({
    where: [
      { parentOrgInvoiceId: ["==", parentOrgInvoice.id] },
      {
        orgId: ["==", parentOrgInvoice.orgId]
      },
      {
        playerBundleId: ["==", parentOrgInvoice.playerBundleId]
      }
    ]
  });

  const childrenOrgInvoices = childrenOrgInvoicesData.docs;

  const playerBundleId = parentOrgInvoice.playerBundleId;

  if (parentOrgInvoice.type !== OrgInvoiceTypes.manual && parentOrgInvoice.type !== OrgInvoiceTypes.registration) {
    throw new Error("The requested invoice is not a parent invoice");
  }

  const allOrgInvoiceIds = [parentOrgInvoice.id, ...childrenOrgInvoices.map(i => i.id)];

  const [playerBundle, orgRefunds, orgPayments] = await Promise.all([
    await h.PlayerBundle.getDoc(playerBundleId),
    Promise.all(
      allOrgInvoiceIds.map(invoiceId =>
        h.OrgPaymentRefund.query({
          where: [{ orgInvoiceId: ["==", invoiceId] }, { orgId: ["==", parentOrgInvoice!.orgId] }]
        }).then(a => a.docs)
      )
    ).then(a => _.flatten(a)),
    Promise.all(
      allOrgInvoiceIds.map(invoiceId =>
        h.OrgPayment.query({ where: [{ invoiceId: ["==", invoiceId] }, { orgId: ["==", parentOrgInvoice!.orgId] }] }).then(
          a => a.docs
        )
      )
    ).then(a => _.flatten(a))
  ]);

  if (!playerBundle) {
    throw new Error("Could not load the associated Player Bundle");
  }

  return {
    parentOrgInvoice: parentOrgInvoice as OrgInvoiceParent,
    childrenOrgInvoices: _.orderBy(childrenOrgInvoices as OrgInvoiceChild[], a => a.autoChargeDateMS, "asc"),
    orgRefunds,
    orgPayments: orgPayments as OrgPaymentInvoice[],
    playerBundle
  };
}

// i18n certified - complete
