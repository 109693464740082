import {
  AccountId,
  OrgCouponId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPaymentType,
  PlayerBundle__AccountType
} from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import { ChargeCardUnexpectedErrorStatusCodes, PayInvoiceUnexpectedErrorStatusCodes } from "../../utils/payment-helpers";
import { payIndividualOrgInvoice } from "../../utils/org-invoice-utils";

export async function orgInvoice__server__payIndividualOrgInvoiceChild(p: {
  orgInvoice: OrgInvoiceChild;
  todayPaymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
  };
  nonDefaultPaymentMethodIdToUse?: string;
  manualTriggerMS?: number;
  selfAccountId: AccountId;
  isAutoPayment: boolean;
  locale: string;
  isImmediateRetry?: boolean;
}): Promise<
  | { type: "success" }
  | {
      type: "error";
      prettyErrorReason: string;
      errorCode: PayInvoiceUnexpectedErrorStatusCodes | ChargeCardUnexpectedErrorStatusCodes;
    }
  | { type: "failed"; prettyFailureReason: string }
> {
  // SERVER_ONLY_TOGGLE

  return await payIndividualOrgInvoice({ ...p, accountId: p.selfAccountId });

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__payIndividualOrgInvoiceChild.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
