import { OrgId, OrgInvoiceId, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function orgInvoice__client__getOrgInvoicesForPlayerBundleId(p: { playerBundleId: PlayerBundleId; orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgInvoices = (
    await h.OrgInvoice.query({
      where: [
        {
          orgId: ["==", p.orgId]
        },
        {
          playerBundleId: ["==", p.playerBundleId]
        }
      ]
    })
  ).docs;

  const orgInvoiceIdChunks = _.chunk(
    orgInvoices.map(oi => oi.id),
    10
  );

  const orgPaymentData = await Promise.all(
    orgInvoiceIdChunks.map(async chunk => {
      return await h.OrgPayment.query({
        where: [
          {
            orgId: ["==", p.orgId]
          },
          {
            invoiceId: ["in", chunk]
          }
        ]
      });
    })
  );
  const orgPayments = _.flatten(orgPaymentData.map(data => data.docs));

  return { orgInvoices, orgPayments };
}

// i18n certified - complete
