import { OrgId, OrgInvoiceId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function orgInvoice__client__getOrgInvoice(p: { id: OrgInvoiceId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.OrgInvoice.getDoc(p.id);
}

// i18n certified - complete
