import {
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgPayment,
  OverallInvoiceStatus,
  ParentInvoiceData
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function orgInvoice__server__getOrgInvoiceNoAuth(p: { orgInvoiceId: OrgInvoiceId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.OrgInvoice.getDoc(p.orgInvoiceId);
}

orgInvoice__server__getOrgInvoiceNoAuth.auth = async (r: express.Request) => {};

// i18n certified - complete
