import {
  AccountId,
  OrgCouponId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPaymentType,
  PaymentMethod,
  PlayerBundle__AccountType
} from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import {
  ChargeCardUnexpectedErrorStatusCodes,
  PayInvoiceUnexpectedErrorStatusCodes,
  chargeWithNMIForInvoice,
  logUnexpectedPaymentError
} from "../../utils/payment-helpers";
import { translate } from "@ollie-sports/i18n";
import moment from "moment";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgInvoice__server__updatePaymentMethodForFutureInvoices(p: {
  orgInvoiceIds: OrgInvoiceId[];
  paymentMethodData: PaymentMethod & {
    accountId: string;
  };
}) {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [accountSecret, orgInvoiceData] = await Promise.all([
    h.AccountSecret.getDoc(p.paymentMethodData.accountId),
    h.OrgInvoice.getDocs(p.orgInvoiceIds)
  ]);

  const orgInvoices = _.compact(orgInvoiceData);

  if (!accountSecret) {
    throw new Error("Can't find account");
  }

  if (!accountSecret.paymentMethodsById?.[p.paymentMethodData.id]) {
    throw new Error("Can't find payment method");
  }

  const orgInvoicesThatHaventBeenPaid = orgInvoices.filter(
    oi =>
      (oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
        oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
      !oi.thisInvoicePaidInFullDateMS
  ) as OrgInvoiceChild[];

  const batchTasks: BatchTask[] = [];

  const newPaymentMethods = Object.keys(accountSecret.paymentMethodsById).reduce((acc, val) => {
    if (val === p.paymentMethodData.id) {
      const paymentMethod = accountSecret.paymentMethodsById?.[val];
      if (paymentMethod) {
        acc[val] = { ...paymentMethod, isDefault: true };
      }
    } else {
      const paymentMethod = accountSecret.paymentMethodsById?.[val];
      if (paymentMethod) {
        acc[val] = { ...paymentMethod, isDefault: false };
      }
    }
    return acc;
  }, {} as Record<string, PaymentMethod>);

  batchTasks.push(
    await h.AccountSecret.update(
      { id: p.paymentMethodData.accountId, doc: { paymentMethodsById: newPaymentMethods } },
      { returnBatchTask: true }
    )
  );

  for (let i = 0; i < orgInvoicesThatHaventBeenPaid.length; i++) {
    const orgInvoice = orgInvoicesThatHaventBeenPaid[i];

    await h.OrgInvoice.update(
      { id: orgInvoice.id, doc: { accountIdScheduledToPay: p.paymentMethodData.accountId } },
      { returnBatchTask: true }
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__updatePaymentMethodForFutureInvoices.auth = async (req: any) => {
  await validateToken(req);
};

// i18n certified - complete
