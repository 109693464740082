import {
  OrgInvoiceTypes,
  OrgInvoice__Registration,
  OrgRegistrationPackage,
  OrgSeason,
  PlayerBundleId
} from "@ollie-sports/models";
import { ObjectKeys } from "../../utils/object-keys";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function generateVirtualOrgInvoiceRegistration(p: {
  playerBundleId: PlayerBundleId;
  orgSeason: OrgSeason;
  orgRegistrationPackage: OrgRegistrationPackage;
}) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const orgInvoice: Omit<OrgInvoice__Registration, "id" | "invoiceGroupId"> = {
    type: OrgInvoiceTypes.registration,
    amountDueCents: p.orgRegistrationPackage.amountCents,
    createdAtMS: Date.now(),
    derivedTotalAmountDueCentsIncludingChildrenInvoices: p.orgRegistrationPackage.amountCents,
    derivedTotalAmountPaidCentsIncludingChildrenInvoices: 0,
    derivedTotalAmountPaidCentsBeforeAllFees: 0,
    memo: p.orgRegistrationPackage.name,
    orgRegistrationPackageId: p.orgRegistrationPackage.id,
    orgSeasonId: p.orgRegistrationPackage.orgSeasonId,
    orgId: p.orgRegistrationPackage.orgId,
    playerBundleId: p.playerBundleId,
    thisInvoicePaidInFullDateMS: 0,
    dueDateMS: p.orgSeason && p.orgRegistrationPackage ? p.orgSeason.registrationDueDateMS : 0,
    lateFeeCentsToBeIssuedIfLate: p.orgRegistrationPackage.paymentInFullLateFeeAmountCents ?? 0
  };
  const orgRegistrationQuestionsInfo = await getOrgRegistrationQuestionInfoForRegistrationPackage(p.orgRegistrationPackage);
  return {
    orgInvoice,
    ...orgRegistrationQuestionsInfo
  };
}

export async function getOrgRegistrationQuestionInfoForRegistrationPackage(orgRegistrationPackage: OrgRegistrationPackage) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgQuestionGroupingIds = ObjectKeys(orgRegistrationPackage.questionGroupingIds ?? {});

  const orgRegistrationQuestionGroupings = _.compact(
    orgQuestionGroupingIds.length ? await h.OrgRegistrationQuestionGrouping.getDocs(orgQuestionGroupingIds) : []
  );
  const questionIdsFromGroupings = _.flatten(orgRegistrationQuestionGroupings.map(g => ObjectKeys(g.questionIds)));
  const allQuestionsIds = [...questionIdsFromGroupings, ...ObjectKeys(orgRegistrationPackage.questionIds ?? {})];
  const orgRegistrationQuestions = _.compact(await h.OrgRegistrationQuestion.getDocs(allQuestionsIds));
  return {
    orgRegistrationQuestions,
    orgRegistrationQuestionGroupings
  };
}
