import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoice__Manual,
  PlayerBundleId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit, DistributivePick } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import { DeepPartial } from "../../utils/type-helpers";

export async function orgInvoice__client__updateIndividualOrgInvoice(p: {
  orgInvoiceId: OrgInvoiceId;
  updatedInvoice: DeepPartial<OrgInvoice>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgInvoice.update({ id: p.orgInvoiceId, doc: p.updatedInvoice });
}

// i18n certified - complete
