import { OrgCoupon, OrgId, OrgInvoiceId, OrgInvoiceTypes, OrgInvoice__Manual, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function orgInvoice__client__deleteOrgInvoice(p: { id: OrgInvoiceId; orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [orgInvoice, childrenInvoicesData] = await Promise.all([
    h.OrgInvoice.getDoc(p.id),
    h.OrgInvoice.query({ where: [{ parentOrgInvoiceId: ["==", p.id] }, { orgId: ["==", p.orgId] }], limit: 1 })
  ]);

  const childrenInvoices = childrenInvoicesData.docs;

  if (!orgInvoice) {
    throw new Error(`Cannot delete an org invoice that cannot be found: ${p.id}`);
  }

  if (orgInvoice.type !== OrgInvoiceTypes.manual) {
    throw new Error(`Cannot delete an org invoice that is not a manual type: ${p.id}`);
  }

  if (childrenInvoices.length) {
    throw new Error(`Cannot delete an invoice that has children: ${p.id}`);
  }

  await h.OrgInvoice.delete({ id: p.id });
}

// i18n certified - complete
