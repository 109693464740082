import { OrgId, OrgInvoice, OrgInvoiceId, OrgPayment, OrgSeason, OrgSettings, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { combineBifrostSubscriptions } from "../../internal-utils/combineBifrostSubscriptions";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";

export function orgInvoice__client__getOrgInvoiceInfoSubscription(p: {
  id: OrgInvoiceId;
  playerBundleId: PlayerBundleId;
  optionalOrgIdHint?: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return combineBifrostSubscriptions({
    invoices: firestoreLiftQuerySubToBifrostSub(
      h.OrgInvoice.querySubscription({
        where: [{ invoiceGroupId: ["==", p.id] }, { playerBundleId: ["==", p.playerBundleId] }]
      })
    ),
    payments: firestoreLiftQuerySubToBifrostSub(
      h.OrgPayment.querySubscription({
        where: [{ invoiceGroupId: ["==", p.id] }, { playerBundleId: ["==", p.playerBundleId] }]
      })
    )
  });
}

// i18n certified - complete
