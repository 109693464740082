import {
  AccountId,
  OrgCouponId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgInvoice__Registration,
  OrgInvoice__RegistrationPaymentPlanInstallment,
  OrgPayment,
  OrgPaymentPlanId,
  OrgPaymentType,
  OrgRegistrationAnswer,
  OrgRegistrationPackage,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestion__AdditionalStep,
  OrgSeason,
  PaymentPlanSinglePaymentInfo,
  TeamId
} from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { translate } from "@ollie-sports/i18n";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import {
  generateOrgInvoiceId,
  getRegistrationEmailBody,
  sendOrgEmail,
  updateRegistrationAndInvoicesLastKeyActionMS
} from "../../utils";
import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import moment from "moment";
import {
  ChargeCardUnexpectedErrorStatusCodes,
  PayInvoiceUnexpectedErrorStatusCodes,
  chargeWithNMIForInvoice,
  logUnexpectedPaymentError
} from "../../utils/payment-helpers";
import { playerBundle__server__getPrettyPlayerBundle } from "../playerBundle";
import { getOrgRegistrationQuestionInfoForRegistrationPackage } from "./org-invoice-helpers";
import { NONE } from "../../constants";

export async function orgInvoice__server__payOrgInvoiceAndPotentiallyGeneratePaymentPlanInstallmentInvoices(p: {
  orgInvoice: OrgInvoice__Manual | Omit<OrgInvoice__Registration, "id" | "invoiceGroupId">;
  todayPaymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCouponDetails?: {
      orgCouponCodeSnapshot: string;
      orgCouponIdSnapshot: OrgCouponId;
      discountAmountCents: number;
    };
  };
  orgPaymentPlanDetails?: {
    orgPaymentPlanId: OrgPaymentPlanId;
    futurePaymentsToSchedule: PaymentPlanSinglePaymentInfo[];
  };
  orgRegistrationAnswers?: Omit<OrgRegistrationAnswer, "id">[];
  selfAccountId: AccountId;
  locale: string;
  nonDefaultPaymentMethodIdToUse?: string;
  hasOutstandingAdditionalSteps: boolean;
  teamIdThatDeterminedPackage?: TeamId;
}): Promise<
  | { type: "success" }
  | {
      type: "error";
      prettyErrorReason: string;
      errorCode: PayInvoiceUnexpectedErrorStatusCodes | ChargeCardUnexpectedErrorStatusCodes;
    }
  | { type: "failed"; prettyFailureReason: string }
> {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  let unexpectedErrorPaymentInfo: any;

  try {
    const nowMS = Date.now();

    const [prettyPlayerBundle, org, orgCoupon, orgSettings, account] = await Promise.all([
      playerBundle__server__getPrettyPlayerBundle({ id: p.orgInvoice.playerBundleId }),
      h.Org.getDoc(p.orgInvoice.orgId),
      p.todayPaymentDetails.appliedOrgCouponDetails
        ? h.OrgCoupon.getDoc(p.todayPaymentDetails.appliedOrgCouponDetails.orgCouponIdSnapshot)
        : null,
      h.OrgSettings.getDoc(p.orgInvoice.orgId),
      h.Account.getDoc(p.selfAccountId)
    ]);

    let orgSeason: OrgSeason | null = null;
    let orgRegistrationPackage: OrgRegistrationPackage | null = null;

    if (!prettyPlayerBundle) {
      logUnexpectedPaymentError({
        errorCode: "pay-invoice-cant-find-player-bundle",
        olliePipe,
        locationId: "mnmd93i4jokkdjf",
        accountId: p.selfAccountId,
        paymentInfo: null,
        info: { playerBundleId: p.orgInvoice.playerBundleId }
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please try again or contact us at support@olliesports.com if the issue persists.",
          serverLocale: p.locale
        }),
        errorCode: "pay-invoice-cant-find-player-bundle"
      };
    }

    if (!org) {
      logUnexpectedPaymentError({
        errorCode: "pay-invoice-cant-find-org",
        olliePipe,
        accountId: p.selfAccountId,
        paymentInfo: null,
        info: { orgId: p.orgInvoice.orgId },
        locationId: "kjl4k4599fd9lewnm"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please try again or contact us at support@olliesports.com if the issue persists.",
          serverLocale: p.locale
        }),
        errorCode: "pay-invoice-cant-find-org"
      };
    }

    if (orgCoupon) {
      if (
        orgCoupon.playerBundleIds &&
        orgCoupon.useLimit &&
        (orgCoupon.numberTimesUsedByPlayerBundleId?.[p.orgInvoice.playerBundleId] ?? 0) >= orgCoupon.useLimit
      ) {
        return {
          type: "failed",
          prettyFailureReason: translate({
            defaultMessage: "The coupon limit has already been exceeded.",
            serverLocale: p.locale
          })
        };
      } else if (
        !orgCoupon.playerBundleIds &&
        orgCoupon.useLimit &&
        orgCoupon.numberTimesUsed &&
        orgCoupon.numberTimesUsed >= orgCoupon.useLimit
      ) {
        return {
          type: "failed",
          prettyFailureReason: translate({
            defaultMessage: "The coupon limit has already been exceeded.",
            serverLocale: p.locale
          })
        };
      } else if (orgCoupon.expirationDateMS && moment().valueOf() > orgCoupon.expirationDateMS) {
        return {
          type: "failed",
          prettyFailureReason: translate({
            defaultMessage: "The coupon has expired.",
            serverLocale: p.locale
          })
        };
      }
    }

    const doesPaymentPlanExist = !!p.orgPaymentPlanDetails && !!p.orgPaymentPlanDetails.futurePaymentsToSchedule.length;

    const teams = _.compact(
      await h.Team.getDocs(
        _.compact(
          Object.values(prettyPlayerBundle.playerBundle.derived.linkedPlayers ?? {}).map(a => {
            if (a.status === "active") {
              return a.teamId;
            }
            return null;
          })
        )
      )
    ).filter(t => t.orgId === p.orgInvoice.orgId);

    const batchTasks: BatchTask[] = [];

    const parentOrgInvoiceId = p.orgInvoice.type === OrgInvoiceTypes.manual ? p.orgInvoice.id : generateOrgInvoiceId();

    const result = await chargeWithNMIForInvoice({
      accountIdToBeCharged: p.selfAccountId,
      paymentDetails: { ...p.todayPaymentDetails, appliedOrgCoupon: orgCoupon ?? undefined },
      orgInvoice: { ...p.orgInvoice, id: parentOrgInvoiceId, invoiceGroupId: parentOrgInvoiceId },
      invoiceGroupId: parentOrgInvoiceId,
      orgId: p.orgInvoice.orgId,
      baseIdempotencyKey:
        parentOrgInvoiceId +
        p.todayPaymentDetails.baseAmountDueCents +
        p.todayPaymentDetails.lateFeeAmountDueCents +
        p.todayPaymentDetails.otherFeesAmountDueCents,
      locale: p.locale,
      nonDefaultPaymentMethodIdToUse: p.nonDefaultPaymentMethodIdToUse
    });

    unexpectedErrorPaymentInfo = "orgPayment" in result ? result.orgPayment : null;

    if (result.type === "error") {
      return result;
    } else if (result.type === "failed") {
      return result;
    }

    // Update the Manual Invoice or Create the Registration Invoice
    if (p.orgInvoice.type === OrgInvoiceTypes.manual) {
      const changes: Partial<OrgInvoice__Manual> = {
        amountDueCents: p.todayPaymentDetails.baseAmountDueCents,
        appliedCouponCodeDetails: p.todayPaymentDetails.appliedOrgCouponDetails,
        derivedTotalAmountPaidCentsIncludingChildrenInvoices: p.todayPaymentDetails.baseAmountDueCents,
        derivedTotalAmountPaidCentsBeforeAllFees: p.todayPaymentDetails.baseAmountDueCents,
        derivedTotalAmountDueCentsIncludingChildrenInvoices:
          p.todayPaymentDetails.baseAmountDueCents +
          _.sum((p.orgPaymentPlanDetails?.futurePaymentsToSchedule ?? []).map(fp => fp.amountCents)),
        thisInvoicePaidInFullDateMS: nowMS
      };

      batchTasks.push(
        await h.OrgInvoice.update(
          {
            id: p.orgInvoice.id,
            doc: changes
          },
          { returnBatchTask: true }
        )
      );
    } else if (p.orgInvoice.type === OrgInvoiceTypes.registration) {
      const regInvoice: OrgInvoice__Registration = {
        ...p.orgInvoice,
        amountDueCents: p.todayPaymentDetails.baseAmountDueCents,
        createdAtMS: nowMS,
        derivedTotalAmountDueCentsIncludingChildrenInvoices:
          p.todayPaymentDetails.baseAmountDueCents +
          _.sum((p.orgPaymentPlanDetails?.futurePaymentsToSchedule ?? []).map(fp => fp.amountCents)),
        derivedTotalAmountPaidCentsBeforeAllFees: p.todayPaymentDetails.baseAmountDueCents,
        derivedTotalAmountPaidCentsIncludingChildrenInvoices: p.todayPaymentDetails.baseAmountDueCents,
        id: parentOrgInvoiceId,
        invoiceGroupId: parentOrgInvoiceId,
        appliedCouponCodeDetails: p.todayPaymentDetails.appliedOrgCouponDetails,
        thisInvoicePaidInFullDateMS: nowMS
      };

      batchTasks.push(
        await h.OrgInvoice.add(
          {
            doc: regInvoice
          },
          { returnBatchTask: true }
        )
      );
      orgRegistrationPackage = await h.OrgRegistrationPackage.getDoc(p.orgInvoice.orgRegistrationPackageId);
      if (!orgRegistrationPackage) {
        logUnexpectedPaymentError({
          errorCode: "pay-invoice-cant-find-package",
          olliePipe,
          accountId: p.selfAccountId,
          paymentInfo: null,
          locationId: "msadnxcvzu34jkl34",
          info: { orgRegistrationPackageId: p.orgInvoice.orgRegistrationPackageId }
        });
        return {
          type: "error",
          prettyErrorReason: translate({
            defaultMessage:
              "Something went wrong. Please try again or contact us at support@olliesports.com if the issue persists.",
            serverLocale: p.locale
          }),
          errorCode: "pay-invoice-cant-find-package"
        };
      }
      orgSeason = await h.OrgSeason.getDoc(orgRegistrationPackage.orgSeasonId);
      const answersToAdd = (p.orgRegistrationAnswers ?? []).map(answerPartial => {
        return { id: h.OrgRegistrationAnswer.generateId(), ...answerPartial } as OrgRegistrationAnswer;
      });
      for (let i = 0; i < answersToAdd.length; i++) {
        const answer = answersToAdd[i];
        batchTasks.push(
          await h.OrgRegistrationAnswer.add(
            {
              doc: answer
            },
            { returnBatchTask: true }
          )
        );
      }
      batchTasks.push(
        await h.OrgRegistration.add(
          {
            doc: {
              id: h.OrgRegistration.generateId(),
              affiliatedOrgTeamsSnapshot: teams.reduce((acc, t) => {
                acc[t.id] = {
                  name: t.name
                };
                return acc;
              }, {} as Record<TeamId, { name: string }>),
              createdAtMS: nowMS,
              orgId: p.orgInvoice.orgId,
              orgRegistrationPackageId: p.orgInvoice.orgRegistrationPackageId,
              orgSeasonId: orgRegistrationPackage.orgSeasonId,
              playerBundleId: p.orgInvoice.playerBundleId,
              orgInvoiceId: parentOrgInvoiceId,
              answerIds: answersToAdd.length
                ? answersToAdd.reduce((acc, val) => {
                    acc[val.id] = true;
                    return acc;
                  }, {} as Record<string, true>)
                : undefined,
              orgNameSnapshot: org.name,
              completedAllAdditionalStepsAtMS: p.hasOutstandingAdditionalSteps ? 0 : nowMS,
              relevantPackageInfoSnapshot: {
                amountCents: orgRegistrationPackage.amountCents,
                lineItems: orgRegistrationPackage.lineItems,
                teamIdThatDeterminedPackage: p.teamIdThatDeterminedPackage ?? NONE
              }
            }
          },
          { returnBatchTask: true }
        )
      );
    }

    // Create Payment Plan Installment Invoices
    if (p.orgPaymentPlanDetails && doesPaymentPlanExist) {
      const orgPaymentPlan = await h.OrgPaymentPlan.getDoc(p.orgPaymentPlanDetails.orgPaymentPlanId);
      if (!orgPaymentPlan) {
        logUnexpectedPaymentError({
          errorCode: "pay-invoice-cant-find-payment-plan",
          olliePipe,
          accountId: p.selfAccountId,
          paymentInfo: null,
          locationId: "234nmndmnswe9009009weaa",
          info: { orgPaymentPlanId: p.orgPaymentPlanDetails.orgPaymentPlanId }
        });
        return {
          type: "error",
          prettyErrorReason: translate({
            defaultMessage:
              "Something went wrong. Please try again or contact us at support@olliesports.com if the issue persists.",
            serverLocale: p.locale
          }),
          errorCode: "pay-invoice-cant-find-payment-plan"
        };
      }
      for (let i = 0; i < p.orgPaymentPlanDetails.futurePaymentsToSchedule.length; i++) {
        const thisPaymentDetails = p.orgPaymentPlanDetails.futurePaymentsToSchedule[i];
        if (p.orgInvoice.type === OrgInvoiceTypes.manual) {
          const obj: OrgInvoice__ManualPaymentPlanInstallment = {
            id: generateOrgInvoiceId(),
            amountDueCents: thisPaymentDetails.amountCents,
            autoChargeDateMS: thisPaymentDetails.manualDateMS ?? thisPaymentDetails.originalPaymentDateMS,
            invoiceGroupId: parentOrgInvoiceId,
            createdAtMS: nowMS,
            derivedTotalAmountPaidCentsBeforeAllFees: 0,
            dueDateMS: thisPaymentDetails.dueDateMS,
            lateFeeCentsToBeIssuedIfLate: orgPaymentPlan.lateFeeAmountCents ?? 0,
            orgId: p.orgInvoice.orgId,
            orgPaymentPlanId: p.orgPaymentPlanDetails.orgPaymentPlanId,
            parentOrgInvoiceId,
            playerBundleId: p.orgInvoice.playerBundleId,
            type: OrgInvoiceTypes.manualPaymentPlanInstallment,
            memo: translate.common("en-us").PaymentPlanInstallment,
            accountIdScheduledToPay: p.selfAccountId,
            thisInvoicePaidInFullDateMS: 0
          };

          batchTasks.push(await h.OrgInvoice.add({ doc: obj }, { returnBatchTask: true }));
        } else {
          const doc: OrgInvoice__RegistrationPaymentPlanInstallment = {
            id: generateOrgInvoiceId(),
            invoiceGroupId: parentOrgInvoiceId,
            amountDueCents: thisPaymentDetails.amountCents,
            autoChargeDateMS: thisPaymentDetails.manualDateMS ?? thisPaymentDetails.originalPaymentDateMS,
            createdAtMS: nowMS,
            derivedTotalAmountPaidCentsBeforeAllFees: 0,
            dueDateMS: thisPaymentDetails.dueDateMS,
            lateFeeCentsToBeIssuedIfLate: orgPaymentPlan.lateFeeAmountCents ?? 0,
            orgId: p.orgInvoice.orgId,
            orgPaymentPlanId: p.orgPaymentPlanDetails.orgPaymentPlanId,
            parentOrgInvoiceId,
            playerBundleId: p.orgInvoice.playerBundleId,
            type: OrgInvoiceTypes.registrationPaymentPlanInstallment,
            memo: translate.common("en-us").PaymentPlanInstallment,
            orgRegistrationPackageId: p.orgInvoice.orgRegistrationPackageId,
            orgSeasonId: p.orgInvoice.orgSeasonId,
            accountIdScheduledToPay: p.selfAccountId,
            thisInvoicePaidInFullDateMS: 0
          };

          batchTasks.push(await h.OrgInvoice.add({ doc }, { returnBatchTask: true }));
        }
      }
    }

    try {
      await h._BatchRunner.executeBatch(batchTasks);
      if (p.orgInvoice.type === OrgInvoiceTypes.registration && orgSeason && account && orgRegistrationPackage) {
        const [successMessage, questionInfo] = await Promise.all([
          orgRegistrationPackage.successMessageId
            ? await h.OrgRegistrationSuccessMessage.getDoc(orgRegistrationPackage.successMessageId)
            : undefined,
          await getOrgRegistrationQuestionInfoForRegistrationPackage(orgRegistrationPackage)
        ]);

        const additionalSteps = questionInfo.orgRegistrationQuestions.filter(
          q => q.type === OrgRegistrationQuestionType.additionalStep
        ) as OrgRegistrationQuestion__AdditionalStep[];

        try {
          await sendOrgEmail({
            orgId: p.orgInvoice.orgId,
            org,
            orgSettings: orgSettings ?? undefined,
            personalizations: [
              {
                email: account.email,
                message: getRegistrationEmailBody({
                  locale: p.locale,
                  orgSeasonName: orgSeason.name,
                  playerName: `${prettyPlayerBundle.derived.accountInfo.firstName} ${prettyPlayerBundle.derived.accountInfo.lastName}`,
                  successMessage: successMessage ?? undefined,
                  additionalSteps: additionalSteps
                }),
                name: `${account.firstName} ${account.lastName}`,
                subject: translate(
                  { defaultMessage: "{orgName} Registration Confirmation", serverLocale: p.locale },
                  { orgName: org.name }
                )
              }
            ],
            replyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
          });
        } catch (e) {
          logUnexpectedPaymentError({
            errorCode: "pay-individual-invoice-email-confirmation-error",
            accountId: p.selfAccountId,
            paymentInfo: null,
            olliePipe,
            locationId: "23kl4jsdf9g8sdfjanermner",
            info: e
          });

          // Log an error because the confirmation email failed but don't error out
        }
      }
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-invoice-batch-tasks-error",
        olliePipe,
        accountId: p.selfAccountId,
        paymentInfo: unexpectedErrorPaymentInfo,
        locationId: "23kl4jkdf93924janermner",
        info: e
      });
      return {
        errorCode: "pay-invoice-batch-tasks-error",
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
          serverLocale: p.locale
        })
      };
    }

    try {
      await Promise.all([
        triggerForPaymentNotifications({
          type: "successfulParentOrgInvoicePayment",
          doesPaymentPlanExist,
          orgInvoiceParent: { ...p.orgInvoice, id: parentOrgInvoiceId, invoiceGroupId: parentOrgInvoiceId },
          orgPayment: result.orgPayment
        }),
        updateRegistrationAndInvoicesLastKeyActionMS({
          accountIds: Object.keys(prettyPlayerBundle.playerBundle.managingAccounts ?? {})
        })
      ]);
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-invoice-notifications-error",
        accountId: p.selfAccountId,
        paymentInfo: unexpectedErrorPaymentInfo,
        locationId: "o234kxclkKJDkerjkd",
        olliePipe,
        info: e
      });
    }

    return { type: "success" };
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "pay-invoice-completely-mysterious-error",
      olliePipe,
      accountId: p.selfAccountId,
      paymentInfo: unexpectedErrorPaymentInfo,
      info: e,
      locationId: "ADKJRMNidfjkejrkljasdfiuer"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "pay-invoice-completely-mysterious-error"
    };
  }

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__payOrgInvoiceAndPotentiallyGeneratePaymentPlanInstallmentInvoices.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
