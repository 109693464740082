import { OrgCoupon, OrgInvoice__Manual, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgInvoice__client__updateManualOrgInvoice(p: { updatedInvoice: OrgInvoice__Manual }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const batchTasks: BatchTask[] = [];

  await h.OrgInvoice.update({ id: p.updatedInvoice.id, doc: p.updatedInvoice });
}

// i18n certified - complete
